"use strict";

import firebase from "firebase";
import Vue from "vue";
import Router from "vue-router";

import routerConfig from "@/routerConfig.js";

import displayOrder from "@/settings/displayOrder.js";

Vue.use(Router);

const routes = [];

// 複数のIDを配列として渡す
const setPropsId = (routes) => {
    const id = [];
    if (routes.params.userId) {
        id.push(routes.params.userId);
    }
    if (routes.params.updateId) {
        id.push(routes.params.updateId);
    }
    return id;
};

for (const path in routerConfig) {

    const data = routerConfig[path];
    const name = data.name;
    const page = data.page;
    const root = displayOrder[path][0];

    // root
    routes.push({
        "path": path,
        "name": name,
        "redirect": {
            "name": path === "/" ? root : `${root}_list`,
        },
    });

    // authentication
    routes.push({
        "path": path,
        "component": () => import("./layouts/" + data.authentication + ".vue"),
        "children": [
            {
                "path": "signin",
                "name": `${name}_signin`,
                "component": () => import("./views/SignIn"),
                "props": () => ({
                    "name": name,
                }),
                "meta": {
                    "title": "Sign In",
                },
            },
            {
                "path": "signout",
                "name": `${name}_signout`,
                "component": () => import("./views/SignOut"),
                "props": () => ({
                    "name": name,
                }),
                "meta": {
                    "title": "Sign Out",
                },
            },
            {
                "path": "signup",
                "name": `${name}_signup`,
                "component": () => import("./views/SignUp"),
                "props": () => ({
                    "name": name,
                }),
                "meta": {
                    "title": "Sign Up",
                },
            },
        ],
    });

    // page
    if (path === "/") {
        for (const key in page) {
            const routeData = {
                "path": `/${key}`,
                "component": () => import("./layouts/" + data.component + ".vue"),
                "children": [],
            };
            if (page[key].complete) {
                routeData.children.push({
                    "path": "create/complete",
                    "name": `${key}_create_complete`,
                    "component": () => import("./views/Complete.vue"),
                    "props": () => ({
                        "editMode": "create",
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": false,
                    },
                });
                routeData.children.push({
                    "path": "update/complete",
                    "name": `${key}_update_complete`,
                    "component": () => import("./views/Complete.vue"),
                    "props": () => ({
                        "editMode": "update",
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": false,
                    },
                });
            }
            if (page[key].confirm) {
                routeData.children.push({
                    "path": "create/confirm",
                    "name": `${key}_create_confirm`,
                    "component": () => import("./views/Confirm.vue"),
                    "props": () => ({
                        "editMode": "create",
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": false,
                    },
                });
                routeData.children.push({
                    "path": "update/confirm",
                    "name": `${key}_update_confirm`,
                    "component": () => import("./views/Confirm.vue"),
                    "props": () => ({
                        "editMode": "update",
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": false,
                    },
                });
            }
            if (page[key].input) {
                routeData.children.push({
                    "path": "create",
                    "name": `${key}_create_input`,
                    "component": () => import("./views/" + page[key].component + ".vue"),
                    "props": () => ({
                        "editMode": "create",
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": page[key].requiresAuth,
                    },
                });
                routeData.children.push({
                    "path": "update/:id",
                    "name": `${key}_update_input`,
                    "component": () => import("./views/" + page[key].component + ".vue"),
                    "props": routes => ({
                        "editMode": "update",
                        "id": routes.params.id,
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": page[key].requiresAuth,
                    },
                });
            }
            else {
                routeData.children.push({
                    "path": "",
                    "name": `${key}`,
                    "component": () => import("./views/" + page[key].component + ".vue"),
                    "props": () => ({
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": page[key].requiresAuth,
                    },
                });
            }
            routes.push(routeData);
        }
    }
    if (path === "/dashboard") {
        for (const key in page) {
            const component = page[key].component;
            const history = page[key].history;
            const list = component ? component.list : "List";
            const detail = component ? component.detail : "Detail";
            const routeData = {
                "path": `/${name}/${key}`,
                "component": () => import("./layouts/" + data.component + ".vue"),
                "children": [
                    {
                        "path": "",
                        "name": `${key}_list`,
                        "component": () => import("./views/" + list + ".vue"),
                        "props": routes => ({
                            "historyLink": history,
                            "id": setPropsId(routes),
                            "name": key,
                        }),
                        "meta": {
                            "requiresAuth": true,
                        },
                    },
                    {
                        "path": "create",
                        "name": `${key}_create`,
                        "component": () => import("./views/" + detail + ".vue"),
                        "props": routes => ({
                            "editMode": "create",
                            "id": setPropsId(routes),
                            "name": key,
                        }),
                        "meta": {
                            "requiresAuth": true,
                        },
                    },
                    {
                        "path": "update/:updateId",
                        "name": `${key}_update`,
                        "component": () => import("./views/" + detail + ".vue"),
                        "props": routes => ({
                            "editMode": "update",
                            "id": setPropsId(routes),
                            "name": key,
                        }),
                        "meta": {
                            "requiresAuth": true,
                        },
                    },
                    {
                        "path": "detail/:updateId",
                        "name": `${key}_detail`,
                        "component": () => import("./views/" + detail + ".vue"),
                        "props": routes => ({
                            "editMode": "detail",
                            "id": setPropsId(routes),
                            "name": key,
                        }),
                        "meta": {
                            "requiresAuth": true,
                        },
                    },
                ],
            };
            if (history) {
                routeData.children.push({
                    "path": "history",
                    "name": `${key}_history_list`,
                    "component": () => import("./views/List.vue"),
                    "props": routes => ({
                        "history": true,
                        "id": setPropsId(routes),
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                });
                routeData.children.push({
                    "path": "history/detail/:updateId",
                    "name": `${key}_history_detail`,
                    "component": () => import("./views/" + detail + ".vue"),
                    "props": routes => ({
                        "editMode": "detail",
                        "history": true,
                        "id": setPropsId(routes),
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                });
            }
            routes.push(routeData);
        }
    }
}

// setting
routes.push({
    "path": "/",
    "component": () => import("./layouts/Standard.vue"),
    "children": [
        {
            "path": "setting/management",
            "name": "setting_management",
            "component": () => import("./views/SettingUse.vue"),
            "props": () => ({
                "getters": "getManagements",
                "name": "managements",
            }),
            "meta": {
                "requiresAuth": true,
            },
        },
    ],
});

routes.push({
    "path": "/",
    "component": () => import("./layouts/Standard.vue"),
    "children": [
        {
            "path": "setting/module",
            "name": "setting_module",
            "component": () => import("./views/SettingUse.vue"),
            "props": () => ({
                "getters": "getModules",
                "name": "modules",
            }),
            "meta": {
                "requiresAuth": true,
            },
        },
    ],
});

// update
routes.push({
    "path": "/update",
    "component": () => import("./layouts/Update.vue"),
    "children": [
        {
            "path": "",
            "name": "update",
            "component": () => import("./views/Update.vue"),
            "props": () => ({
                "name": "update",
            }),
            "meta": {
                "requiresAuth": true,
            },
        },
    ],
});

// other
routes.push({
    "path": "*",
});

// new router
const router = new Router({
    "mode": "history",
    "base": process.env.BASE_URL,
    "routes": routes,
    scrollBehavior() {
        return {
            "x": 0,
            "y": 0,
        };
    },
});

// auth check
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth) {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                next();
            }
            else {
                next({
                    "path": from.name === `${routerConfig["/"].name}_signout` ? "/signin" : "/dashboard/signin",
                });
            }
        });
    }
    else {
        next();
    }
});

export default router;
