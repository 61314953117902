"use strict";

const mailText = 
`返却予定日が登録されました。

ニックネーム：｛ニックネーム｝
お客様ID：｛お客様ID｝
返却予定日：｛返却予定日｝
返却予定時間：｛返却予定時間｝
`;

export default {
    "design": {
        "style": "isYellow",
    },
    "root": "user/item",
    "copy": "Sanyo",
    "mail": {
        "reservation": {
            "to": "nao.imaida@gmail.com",
            "from": "info@sanyo-kimono.net",
            "subject": "【KIMONO】返却予定日が登録されました。",
            "text": mailText,
        },
    },
};
