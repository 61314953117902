"use strict";

export default {
    "user": {
        "use": true,
        "title": "お客様",
        "textset": "master",
        "collection": "users",
        "column": [
            // "updateId",
            "name",
            "tel",
            // "postalCode",
            // "address",
            // "mailAddress",
            "memo",
            "&nbsp",
        ],
        "columnWidth": [140, 100, "*"],
        "button": {
            "create": false,
            "update": true,
            "delete": true,
            "detail": false,
        },
        "delete": false,
        "nest": [
            "user/:userId/item",
        ],
    },
    "user/item": {
        "use": true,
        "title": "お預かり着物",
        "textset": "data",
        "collection": "users/items",
        "column": [
            "reservationDate",
            "reservationTime",
            "receptionDate",
            "image",
        ],
        "completed": "returnDatetime",
    },
    "user/:userId/item": {
        "use": true,
        "title": "着物",
        "textset": "data",
        "collection": "users/items",
        "column": [
            "image",
            "reservationDate",
            "reservationTime",
            "freeText",
            "receptionDate",
            "&nbsp",
        ],
        "columnWidth": [80, 100, 80, "*", 100],
        "completed": "returnDatetime",
        "button": {
            "create": true,
            "update": true,
            "delete": true,
            "detail": false,
            "back": "user",
        },
        "delete": false,
    },
    "item": {
        "use": false,
        "title": "商品",
        "textset": "master",
        "collection": "items",
        "column": [
            "image",
            "itemNumber",
            "itemName",
            "itemDescription",
            "price",
            "stock",
            "&nbsp",
        ],
        "columnWidth": [80, 80, 140, "*", 60, 30],
        "button": {
            "create": true,
            "update": true,
            "delete": true,
            "detail": false,
        },
    },
    "driver": {
        "use": false,
        "title": "ドライバー",
        "textset": "master",
        "collection": "drivers",
        "column": [
            "updateId",
            "name",
            "&nbsp",
        ],
        "columnWidth": ["*", "*"],
        "button": {
            "create": false,
            "update": true,
            "delete": true,
            "detail": false,
        },
    },
    "order": {
        "use": true,
        "title": "注文",
        "textset": "data",
        "collection": "orders",
        "column": [
            "id",
            "name",
            "tel",
            "paymentMethod",
            "driverName",
            "comment",
            "&nbsp",
        ],
        "columnWidth": [120, 120, 80 , 100, 120, "*"],
        "button": {
            "create": false,
            "update": false,
            "delete": true,
            "detail": true,
        },
    },
    "cloak": {
        "use": true,
        "title": "預かり物",
        "textset": "data",
        "collection": "users",
        "collectionGroup": "items",
        "connect": "user/:userId/item",
        "column": [
            "image",
            "reservationDate",
            "reservationTime",
            "name",
            "tel",
            "freeText",
            "receptionDate",
            "&nbsp",
        ],
        "columnWidth": [80, 120, 80, 100, 80, "*", 100],
        "completed": "returnDatetime",
        "button": {
            "create": false,
            "update": true,
            "delete": true,
            "detail": false,
            "status": {
                "return": true,
            },
        },
        "delete": false,
    },
    "receptionTime": {
        "use": true,
        "title": "受付時間",
        "textset": "data",
        "collection": "receptionTimes",
        "column": [
            "&nbsp",
        ],
        "columnWidth": ["*"],
        "button": {
            "create": false,
            "update": true,
            "delete": false,
            "detail": false,
        },
    },
    "closedDate": {
        "use": true,
        "title": "休業日",
        "textset": "data",
        "collection": "closedDates",
        "column": [
            "closedDate",
            "&nbsp",
        ],
        "columnWidth": ["*"],
        "button": {
            "create": true,
            "update": true,
            "delete": true,
            "detail": false,
        },
    },
};
