"use strict";

export default {
    "/": {
        "name": "standard",
        "authentication": "Standard",
        "component": "Standard",
        "page": {
            "user/item": {
                "component": "ItemList",
                "requiresAuth": true,
            },
        },
    },
    "/dashboard": {
        "name": "dashboard",
        "authentication": "Authentication",
        "component": "Dashboard",
        "page": {
            "cloak": {
                "history": true,
            },
            "order": {
                "history": true,
            },
            "user": {
                // "component": {
                //     "detail": "Detail",
                //     "list": "List",
                // },
            },
            "user/:userId/item": {
                "history": true,
            },
            "receptionTime": {
                "component": {
                    "list": "DetailReceptionTime",
                },
            },
            "closedDate": {},
        },
    },
};
