"use strict";

export default {
    "/": [
        // "item",
        "cloak",
    ],
    "/dashboard": [
        // "order",
        "cloak",
        "user",
        // "item",
        // "driver",
        "receptionTime",
        "closedDate",
    ],
};
