"use strict";

export default {
    "logo": {
        "use": true,
        "name": "Logo",
        "option": {
            "character": {
                "main": {
                    "gradation": {
                        "color": [
                            {
                                "color": "#e6b980",
                                "offset": "0%",
                            },
                            {
                                "color": "#eacda3",
                                "offset": "100%",
                            },
                        ],
                        // "degree": 0,
                    },
                    "text": "KIMONO",
                    // "text": "Homemade <br>Bakery",
                },
                "sub": {
                    "color": "#eacda3",
                    "text": "Storage Service",
                },
            },
            "mark": {
                // "color": "#fcb900",
                "gradation": {
                    "color": [
                        {
                            "color": "#abecd6",
                            "offset": "0%",
                            "opacity": "1",
                        },
                        // {
                        //     "color": "#fff",
                        //     "offset": "50%",
                        //     "opacity": ".7",
                        // },
                        {
                            "color": "#fbed96",
                            "offset": "100%",
                            "opacity": "1",
                        },
                    ],
                    // "degree": {
                    //     "x1": 0,
                    //     "x2": 1,
                    //     "y1": 1,
                    //     "y2": 1,
                    // },
                },
                "path": "M22 18.055v2.458c0 1.925-4.655 3.487-10 3.487-5.344 0-10-1.562-10-3.487v-2.458c2.418 1.738 7.005 2.256 10 2.256 3.006 0 7.588-.523 10-2.256zm-10-18.055c-5.344 0-10 1.562-10 3.488s4.656 3.487 10 3.487c5.345 0 10-1.562 10-3.487 0-1.926-4.655-3.488-10-3.488zm0 8.975c-3.006 0-7.588-.523-10-2.256v2.44c0 1.926 4.656 3.487 10 3.487 5.345 0 10-1.562 10-3.487v-2.44c-2.418 1.738-7.005 2.256-10 2.256zm-6.023 5.02l-.495 3.473c.373.112.772.215 1.192.308l.505-3.535c-.414-.073-.816-.155-1.202-.246zm8.564.54l-.527 3.706c.429-.03.845-.071 1.251-.12l.529-3.722c-.409.054-.827.099-1.253.136zm2.197-.28l-.53 3.732c.439-.071.862-.153 1.266-.246l.532-3.743c-.407.097-.831.182-1.268.257zm-4.37.384l-.521 3.672c.428 0 .721 0 1.235-.02l.525-3.691c-.408.021-.822.034-1.239.039zm8.353 1.892c.813-.505 1.279-1.087 1.279-1.707v-2.439c-.23.16-.482.313-.755.458l-.524 3.688zm-16.819-3.168l-.468 3.26c.337.195.725.377 1.162.544l.487-3.407c-.395-.114-.804-.249-1.181-.397zm6.314 1.226l-.517 3.629c.399.033.808.057 1.224.073l.521-3.655c-.414-.007-.824-.023-1.228-.047zm-8.216-2.204v2.439c0 .415.21.813.592 1.183l.436-3.024c-.381-.187-.723-.386-1.028-.598zm6.085 1.997l-.51 3.569c.391.067.794.126 1.211.175l.514-3.605c-.413-.038-.819-.084-1.215-.139zm10.88-.636l-.533 3.748c.471-.138.904-.291 1.296-.457l.531-3.737c-.427.17-.808.303-1.294.446z",
                // "path": "M5 11v1h8v-7h-10v-1c0-.552.448-1 1-1h10c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.089-.743 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-1c-.552 0-1-.448-1-1v-6h-2v-2h7v2h-3zm3 5.8c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-3-2.8h-10v2h.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6zm1-5v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-16-3h8v2h-8v-2z",
                // "path": "M22.224 14.928c-.634.313-1.212.471-1.53 1.14-.371.781-.094 1.372.409 2.061.517.721-.22 1.133-1.103 1.153v-8.432l.29-.263.001.01c.428.154.503.353.539.81.073 1-.081 1.938 1.217 2.759.451.268.717.475.177.762m-4.224-5.21v10.282h-12v-10.282c-1.127-.484-2.053-1.459-1.967-2.969.148-2.593 5.123-2.755 7.836-2.773 2.908-.005 8.155.36 8.155 3.067 0 1.078-.854 2.175-2.024 2.675m-14.532 9.625c-.116-.734-.103-1.066-.614-1.61-.318-.338-.605-.474-.982-.718-.534-.347-.398-.725.107-1.09.245-.171.503-.327.734-.518.604-.497.924-1.2.742-1.94-.11-.451-.349-.747-.602-1.134-.611-.936.014-1.657.852-1.777v-.001l.295.295v8.776c-.275.003-.485-.016-.532-.283m19.801-6.19c-.579-.392-.855-.372-.908-1.07-.066-1.078-.01-2.008-1.068-2.661h-.006c.677-1.03.863-2.165.674-3.182-.671-3.626-6.103-4.213-9.792-4.24-4.588-.009-10.094.43-10.142 4.907-.011 1 .234 1.724.652 2.407l-.01-.001c-1.061.512-1.609 1.476-1.592 2.329.015.809.345 1.231.761 1.871.22.336.292.468-.231.853-.896.622-1.616 1.061-1.607 2.217.01 1.059.663 1.428 1.49 1.961.522.348.414.461.489.989.15 1.071 1.003 1.643 2.141 1.607l.004-.018c.209.695.675.869 1.4.878h13.055c.878-.002 1.34-.349 1.428-1.219l.001.001c1.8-.043 2.689-1.013 2.766-2.071.06-.837-.279-1.162-.677-1.764-.133-.215-.043-.276.179-.389.956-.467 1.724-.785 1.724-1.959 0-.665-.247-1.092-.731-1.446",
                "size": {
                    "authentication": "96",
                    "header": "32",
                    "menu": "96",
                },
            },
        },
    },
    "filter": {
        "use": true,
        "name": "Search Filter",
        "option": {
            // "user": {
            //     "name": "",
            //     "tel": "exact",
            // },
        },
    },
    "sort": {
        "use": true,
        "name": "Sort",
        "option": {
            "cloak": {
                "field": "reservationDate",
                "type": "desc",
            },
            "user/:userId/item": {
                "field": "receptionDate",
                "type": "desc",
            },
        },
    },
    "pager": {
        "use": true,
        "name": "Pager",
        "option": {
            // "user": {
            //     "line": 2,
            //     "length": 2,
            // },
        },
    },
    "tab": {
        "use": false,
        "name": "Tab",
    },
    "zipAddress": {
        "use": true,
        "name": "Zip Address",
        "option": {},
    },
    "csvDownload": {
        "use": false,
        "name": "CSV Download",
    },
    "pdfDownload": {
        "use": false,
        "name": "PDF Download",
        "option": {
            // "user": {
            //     "columnWidth": [120, 120, 80, 60, "*", 160],
            // },
        },
    },
};
