"use strict";

import Vue from "vue";
import Vuex from "vuex";

import configs from "@/settings/config.js";
import displayOrders from "@/settings/displayOrder.js";
import managements from "@/settings/management.js";
import modules from "@/settings/module.js";

Vue.use(Vuex);

export default new Vuex.Store({
    "strict": process.env.NODE_ENV !== "production",
    "state": {
        "settings": {
            "configs": configs,
            "displayOrders": displayOrders,
            "managements": managements,
            "modules": modules,
        },
        "transceiver": {},
    },
    "getters": {
        "getConfigs": state => state.settings.configs,
        "getDisplayOrders": state => state.settings.displayOrders,
        "getManagements": state => state.settings.managements,
        "getModules": state => state.settings.modules,
        "getTransceiver": state => state.transceiver,
    },
    "actions": {
        setSettings({ commit }, data) {
            commit("setSettings", data);
        },
        setTransceiver({ commit }, data) {
            commit("setTransceiver", data);
        },
    },
    "mutations": {
        setSettings(state, data) {
            state.settings = data;
        },
        setTransceiver(state, data) {
            state.transceiver = data;
        },
    },
});
