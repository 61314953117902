"use strict";

import firebase from "firebase";
import Vue from "vue";

import App from "@/App.vue";
import router from "@/router.js";
import store from "@/store.js";

Vue.config.productionTip = false;

const firebaseConfig = {
    "apiKey": "AIzaSyC6p-vbjoSj188MNbTotnfsJYvDCWut-rM",
    "authDomain": "kimonostorageservice.firebaseapp.com",
    "projectId": "kimonostorageservice",
    "storageBucket": "kimonostorageservice.appspot.com",
    "messagingSenderId": "1024193239697",
    "appId": "1:1024193239697:web:f5e09c3cc1ba2373483d30",
    "measurementId": "G-MK78QCE7E3",
};

firebase.initializeApp(firebaseConfig);

new Vue({
    router,
    store,
    "render": h => h(App),
}).$mount("#app");
